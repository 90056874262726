import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Row, Col } from 'reactstrap'

import Layout from '../layout'

import BackgroundContainer from '../components/image/background-container'
import TitleContainer from '../components/common/title-container'
import Title from '../components/common/title'
import Subtitle from '../components/common/subtitle'

import PageContainer from '../components/common/page-container'

import Map from '../components/contact/map'
import Form from '../components/contact/form'

const FormIntro = styled.p`
  font-weight: 600;
  font-size: 1.3rem;
`

export default (props) => (
  <Layout title="Contact">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>Contact Us</Title>
        <Subtitle>We'd love to have a chat</Subtitle>
      </TitleContainer>
    </BackgroundContainer>

    <PageContainer>
      <FormIntro>
        If you're looking to start your next project, we'd love to hear from you.
      </FormIntro>
      <Row>
        <Col md={7}>
          <Form />
        </Col>
        <Col>
          <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyA4EATVPRTCDlIckYPNS7EYPfKlTYzuE98"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </Col>
      </Row>
    </PageContainer>
  </Layout >
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`