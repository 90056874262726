import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const Map = withScriptjs(withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 54.963835, lng: -1.6060701 }}
  >
    <Marker position={{ lat: 54.963835, lng: -1.6060701 }} />
  </GoogleMap>
)))

export default Map