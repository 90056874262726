import React, { Component } from 'react'
import { Alert, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ContactForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      'bot-field': "",
      submitted: false,
      name: "",
      company: "",
      email: "",
      message: ""
    }
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        'form-name': "contact", ...{
          'bot-field': this.state['bot-field'],
          name: this.state.name,
          company: this.state.company,
          email: this.state.email,
          message: this.state.message
        }
      })
    })
      .then(() => this.setState({
        submitted: true,
        name: "",
        company: "",
        email: "",
        message: ""
      }))
      .catch(error => alert(error));

    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, company, email, message } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} method="post"
        name="contact" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" onChange={this.handleChange} />

        {this.state.submitted && (
          <Alert color="success">
            Message sent - we'll be in touch soon!
          </Alert>
        )}
        <Row>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input id="name" name="name" value={name} onChange={this.handleChange} required={true} />
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="company">Company</Label>
              <Input id="company" name="company" value={company} onChange={this.handleChange} required={true} />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label for="email">Email</Label>
          <Input type="email" id="email" name="email" value={email} onChange={this.handleChange} required={true} />
        </FormGroup>

        <FormGroup>
          <Label for="message">Message</Label>
          <Input
            type="textarea"
            name="message"
            id="message"
            rows={5}
            value={message}
            onChange={this.handleChange}
          />
        </FormGroup>

        <Button color="primary">Submit</Button>
      </Form>
    )
  }
}